export default {
  computed: {
    getTotalsOptions() {
      const tryOnPeriod = 2
      if (this.getPeriod !== tryOnPeriod && this.activePeriod !== tryOnPeriod) return this.product.totals.options;
      if (
        !(
          this.product.product_option &&
          this.product.product_option.extension_attributes
        )
      )
        return this.product.totals.options;

      const newTotals = JSON.parse(JSON.stringify(this.product.totals.options));

      const endDateLabel = "End Date:";
      const endDateId = this.product.custom_options
        .find((option) => option.title === endDateLabel)
        .option_id.toString();
      const endDate = this.product.product_option.extension_attributes.custom_options.find(
        (option) => option.option_id === endDateId
      ).option_value;

      const startDateLabel = "Start Date:";
      const startDateId = this.product.custom_options
        .find((option) => option.title === startDateLabel)
        .option_id.toString();
      const startDate = this.product.product_option.extension_attributes.custom_options.find(
        (option) => option.option_id === startDateId
      ).option_value;

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const newStartDate = new Date(startDate).toLocaleDateString(
        this.storeView.i18n.defaultLocale,
        options
      );
      const newEndDate = new Date(endDate);
      const fakeEndDate = new Date(
        newEndDate.setDate(newEndDate.getDate() - 1)
      ).toLocaleDateString(this.storeView.i18n.defaultLocale, options);

      console.log("newEndDate", newEndDate);
      console.log("fakeEndDate", fakeEndDate);

      newTotals.find((t) => t.label === startDateLabel).value = newStartDate;
      newTotals.find((t) => t.label === endDateLabel).value = fakeEndDate;

      return newTotals;
    },
  },
};
